import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

const withContainer = Component => props => {
  const { group_id: current_group_id } = hooks.useParams()
  const [record_id] = hooks.useFormState('record_id')
  const [modifyVehicle] = hooks.useModelFunction('vehicle', 'modify')
  const [unpairVehicle] = hooks.useModelFunction('vehicle', 'unpair')
  const navigate = hooks.useNavigate()
  const [current_vehicle] = hooks.useModel('vehicle', [record_id], { single: true })
  const onSubmit = useCallback(async ({ group }) => {
    if (!!current_vehicle.driver)
      await unpairVehicle({ vehicle_id: record_id, user_id: current_vehicle.driver })
    await modifyVehicle({ filter: { vehicle_id: record_id }, updates: { parent_license: null, group } })
    navigate(`/adherent/${group}/vehicle/${record_id}`)
  }, [record_id, unpairVehicle, modifyVehicle, navigate, current_vehicle])

  const getFailedNotification = useCallback((err) => {
    if (err.code === 'VEHICLE_HAS_JOB')
      return [I18n.t('vehicle.unpair_has_job'), { variant: 'error' }]
    return [err.message, { variant: 'error' }]
  }, [])
  const mergedProps = {
    onSubmit,
    getFailedNotification,
    current_group_id,
    has_driver: !!current_vehicle?.driver
  }

  return <Component {...mergedProps} />
}

export default withContainer
